var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"popup"},[_c('div',{staticClass:"background",on:{"click":function($event){return _vm.reset()}}}),_c('div',{staticClass:"box"},[_c('div',{staticClass:"steps"},[_c('div',{staticClass:"step",class:!_vm.waiver && _vm.step == 1 ? 'inProcess' : 'done'},[_vm._v(" WAIVER ")]),(
            (_vm.selectedRegistration.price != 0 &&
              _vm.selectedRegistration.payed_by == 'Captain' &&
              !_vm.playerPage) ||
              (_vm.selectedRegistration.price != 0 &&
                _vm.selectedRegistration.payed_by == 'Player' &&
                _vm.playerPage &&
                !_vm.paymentAlreadyMade)
          )?_c('div',{staticClass:"step",class:!_vm.paymentStep && _vm.step == 2 ? 'inProcess' : _vm.step == 3 ? 'done' : ''},[_vm._v(" PAYMENT ")]):_vm._e(),_c('div',{staticClass:"step",class:!_vm.done && _vm.step != 3 ? '' : _vm.done ? 'done' : 'red'},[_vm._v(" DONE ")])]),(_vm.step == 1)?_c('div',{staticClass:"waiver"},[_c('pdf',{attrs:{"src":this.$apiUrl + '/downloadWaiverPDF'}})],1):_vm._e(),(_vm.step == 1)?_c('label',{staticClass:"terms"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.waiver),expression:"waiver"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.waiver)?_vm._i(_vm.waiver,null)>-1:(_vm.waiver)},on:{"change":function($event){var $$a=_vm.waiver,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.waiver=$$a.concat([$$v]))}else{$$i>-1&&(_vm.waiver=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.waiver=$$c}}}}),_vm._v(" Accept terms and conditions ")]):_vm._e(),(_vm.step == 2)?_c('div',[_c('div',{staticStyle:{"font-size":"20px","text-align":"center","padding":"10px"}},[_vm._m(0),_c('p',{staticStyle:{"margin-left":"10px"}},[_vm._v("$"+_vm._s(_vm.selectedRegistration.price))])]),_vm._m(1),_c('label',{staticClass:"paymentLabel",staticStyle:{"margin-top":"20px"}},[_vm._v(" Name on Card")]),_c('input',{staticClass:"paymentField",attrs:{"id":"postal-code","name":"name","placeholder":"John Doe"}}),_c('label',{staticClass:"paymentLabel"},[_vm._v(" Card number")]),_c('div',{staticClass:"paymentField",attrs:{"id":"card-number-element"}}),_vm._m(2),_vm._m(3),_c('label',{staticClass:"paymentLabel"},[_vm._v(" ZIP/Postal code")]),_c('input',{staticClass:"paymentField",attrs:{"id":"postal-code","name":"postal_code","placeholder":"G3X 4K5"}})]):_vm._e(),(_vm.step == 3)?_c('div',{staticStyle:{"text-align":"center"}},[_c('p',{staticStyle:{"padding":"50px"}},[_vm._v(_vm._s(_vm.purchaseMessage))])]):_vm._e(),(
          _vm.step == 1 &&
            ((_vm.selectedRegistration.price != 0 &&
              _vm.selectedRegistration.payed_by == 'Captain' &&
              !_vm.playerPage) ||
              (_vm.selectedRegistration.price != 0 &&
                _vm.selectedRegistration.payed_by == 'Player' &&
                _vm.playerPage &&
                !_vm.paymentAlreadyMade))
        )?_c('button',{staticClass:"Button",attrs:{"disabled":!_vm.waiver},on:{"click":function($event){_vm.step++;
          _vm.createAndMountFormElements();}}},[_vm._v(" NEXT ")]):_vm._e(),(
          _vm.step == 1 &&
            !(
              (_vm.selectedRegistration.price != 0 &&
                _vm.selectedRegistration.payed_by == 'Captain' &&
                !_vm.playerPage) ||
              (_vm.selectedRegistration.price != 0 &&
                _vm.selectedRegistration.payed_by == 'Player' &&
                _vm.playerPage &&
                !_vm.paymentAlreadyMade)
            )
        )?_c('button',{staticClass:"Button",attrs:{"disabled":!_vm.waiver},on:{"click":function($event){return _vm.purchase()}}},[_vm._v(" REGISTER ")]):_vm._e(),(_vm.step == 2)?_c('button',{staticClass:"Button",staticStyle:{"margin-top":"50px"},on:{"click":function($event){return _vm.purchase()}}},[_vm._v(" PAY $"+_vm._s(_vm.selectedRegistration.price)+" ")]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Payment Amount:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-row"},[_c('span',{staticClass:"visa"}),_c('span',{staticClass:"mastercard"}),_c('span',{staticClass:"amex"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"paymentLabel"},[_vm._v(" Expiry date "),_c('div',{staticClass:"paymentField",attrs:{"id":"card-expiry-element"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"paymentLabel"},[_vm._v(" Security code "),_c('div',{staticClass:"paymentField",attrs:{"id":"card-cvc-element"}})])
}]

export { render, staticRenderFns }