<template>
  <div>
    <div class="popup">
      <div class="background" @click="reset()"></div>
      <div class="box">
        <div class="steps">
          <div
            class="step"
            :class="!waiver && step == 1 ? 'inProcess' : 'done'"
          >
            WAIVER
          </div>
          <div
            class="step"
            v-if="
              (selectedRegistration.price != 0 &&
                selectedRegistration.payed_by == 'Captain' &&
                !playerPage) ||
                (selectedRegistration.price != 0 &&
                  selectedRegistration.payed_by == 'Player' &&
                  playerPage &&
                  !paymentAlreadyMade)
            "
            :class="
              !paymentStep && step == 2 ? 'inProcess' : step == 3 ? 'done' : ''
            "
          >
            PAYMENT
          </div>
          <div
            class="step"
            :class="!done && step != 3 ? '' : done ? 'done' : 'red'"
          >
            DONE
          </div>
        </div>

        <div v-if="step == 1" class="waiver">
          <pdf :src="this.$apiUrl + '/downloadWaiverPDF'"></pdf>
        </div>

        <label v-if="step == 1" class="terms"
          ><input type="checkbox" v-model="waiver" /> Accept terms and
          conditions
        </label>

        <div v-if="step == 2">
          <div style="font-size: 20px; text-align:center; padding:10px;">
            <p><b>Payment Amount:</b></p>
            <p style="margin-left: 10px;">${{ selectedRegistration.price }}</p>
          </div>

          <div class="card-row">
            <span class="visa"></span>
            <span class="mastercard"></span>
            <span class="amex"></span>
          </div>

          <label class="paymentLabel" style="margin-top: 20px">
            Name on Card</label
          >
          <input
            class="paymentField"
            id="postal-code"
            name="name"
            placeholder="John Doe"
          />
          <label class="paymentLabel"> Card number</label>
          <div class="paymentField" id="card-number-element"></div>
          <label class="paymentLabel">
            Expiry date
            <div class="paymentField" id="card-expiry-element"></div>
          </label>
          <label class="paymentLabel">
            Security code
            <div class="paymentField" id="card-cvc-element"></div>
          </label>
          <label class="paymentLabel"> ZIP/Postal code</label>
          <input
            class="paymentField"
            id="postal-code"
            name="postal_code"
            placeholder="G3X 4K5"
          />
        </div>

        <div v-if="step == 3" style="text-align: center;">
          <p style="padding: 50px;">{{ purchaseMessage }}</p>
        </div>

        <button
          class="Button"
          v-if="
            step == 1 &&
              ((selectedRegistration.price != 0 &&
                selectedRegistration.payed_by == 'Captain' &&
                !playerPage) ||
                (selectedRegistration.price != 0 &&
                  selectedRegistration.payed_by == 'Player' &&
                  playerPage &&
                  !paymentAlreadyMade))
          "
          :disabled="!waiver"
          @click="
            step++;
            createAndMountFormElements();
          "
        >
          NEXT
        </button>

        <button
          class="Button"
          v-if="
            step == 1 &&
              !(
                (selectedRegistration.price != 0 &&
                  selectedRegistration.payed_by == 'Captain' &&
                  !playerPage) ||
                (selectedRegistration.price != 0 &&
                  selectedRegistration.payed_by == 'Player' &&
                  playerPage &&
                  !paymentAlreadyMade)
              )
          "
          :disabled="!waiver"
          @click="purchase()"
        >
          REGISTER
        </button>

        <button
          class="Button"
          style="margin-top: 50px;"
          v-if="step == 2"
          @click="purchase()"
        >
          PAY ${{ selectedRegistration.price }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pdf from "vue-pdf";
export default {
  props: [
    "selectedRegistration",
    "Client_id",
    "selectedDivision",
    "playerPage",
    "Team_id",
    "Invitation_id",
    "paymentAlreadyMade",
  ],
  components: {
    pdf,
  },
  data() {
    return {
      paymentStep: false,
      step: 1,
      waiver: false,
      done: false,
      payment: false,
      purchaseMessage: "",
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCVCElement: null,
      alreadyRegistered: false,
    };
  },
  methods: {
    reset() {
      this.paymentStep = false;
      this.step = 1;
      this.waiver = false;
      this.done = false;
      this.$emit("updateState", this.payment);
      this.purchaseMessage = "";
    },
    async purchase() {
      if (!this.playerPage) {
        if (this.selectedRegistration.payed_by == "Captain") {
          this.stripe
            .createToken(this.cardNumberElement)
            .then(async (result) => {
              if (result.error) {
                console.log(result.error);
              } else {
                var stripeObject = {
                  price: this.selectedRegistration.price,
                  source: result.token,
                  Client_id: this.Client_id,
                  product: this.selectedRegistration,
                };

                await axios
                  .post(
                    this.$apiUrl + "/registration/team/purchase",
                    {
                      stripeToken: stripeObject,
                      League_id: this.selectedDivision._id,
                      Season_id: this.selectedRegistration.season_id,
                      Registration_id: this.selectedRegistration._id,
                      Team_id: this.Client_id,
                      payed_by: this.selectedRegistration.payed_by,
                      Email: `${
                        JSON.parse(atob(localStorage.userToken.split(".")[1]))
                          .Email
                      }`,
                    },
                    {
                      headers: {
                        token: `${localStorage.userToken}`,
                      },
                    }
                  )
                  .then(async (res) => {
                    if (res.data == "Success") {
                      this.step++;
                      this.paymentStep = true;
                      this.done = true;
                    } else {
                      this.step++;
                      this.paymentStep = true;
                      this.done = false;
                    }
                    this.$emit("getTeamRegistrations");
                    this.purchaseMessage = res.data;
                  });
              }
            });
        } else {
          await axios
            .post(
              this.$apiUrl + "/registration/team/purchase",
              {
                League_id: this.selectedDivision._id,
                Season_id: this.selectedRegistration.season_id,
                Registration_id: this.selectedRegistration._id,
                Team_id: this.Client_id,
                payed_by: this.selectedRegistration.payed_by,
                Email: `${
                  JSON.parse(atob(localStorage.userToken.split(".")[1])).Email
                }`,
              },
              {
                headers: {
                  token: `${localStorage.userToken}`,
                },
              }
            )
            .then(async (res) => {
              if (res.data == "Success") {
                this.step = 3;
                this.paymentStep = true;
                this.done = true;
              } else {
                this.step = 3;
                this.paymentStep = true;
                this.done = false;
              }
              this.purchaseMessage = res.data;
              this.$emit("getTeamRegistrations");
            });
        }
      } else {
        if (
          this.selectedRegistration.payed_by == "Player" &&
          !this.paymentAlreadyMade
        ) {
          this.stripe
            .createToken(this.cardNumberElement)
            .then(async (result) => {
              if (result.error) {
                console.log(result.error);
              } else {
                var stripeObject = {
                  price: this.selectedRegistration.price,
                  source: result.token,
                  Client_id: this.Client_id,
                  product: this.selectedRegistration,
                };
                if (this.Team_id) {
                  await axios
                    .post(
                      this.$apiUrl + "/registration/player/accept",
                      {
                        stripeToken: stripeObject,
                        League_id: this.selectedRegistration.league_id,
                        Season_id: this.selectedRegistration.season_id,
                        Registration_id: this.selectedRegistration._id,
                        Invitation_id: this.Invitation_id,
                        Player_id: this.Client_id,
                        payed_by: this.selectedRegistration.payed_by,
                        Team_id: this.Team_id,
                        Email: `${
                          JSON.parse(atob(localStorage.userToken.split(".")[1]))
                            .Email
                        }`,
                      },
                      {
                        headers: {
                          token: `${localStorage.userToken}`,
                        },
                      }
                    )
                    .then(async (res) => {
                      if (res.data == "Success") {
                        this.step = 3;
                        this.paymentStep = true;
                        this.done = true;
                      } else {
                        this.step = 3;
                        this.paymentStep = true;
                        this.done = false;
                      }
                      this.purchaseMessage = res.data;
                      this.$emit("updateInvitations");
                    });
                } else {
                  await axios
                    .post(
                      this.$apiUrl + "/registration/player",
                      {
                        stripeToken: stripeObject,
                        League_id: this.selectedDivision._id,
                        Season_id: this.selectedRegistration.season_id,
                        Registration_id: this.selectedRegistration._id,
                        Player_id: this.Client_id,
                        payed_by: this.selectedRegistration.payed_by,
                        Email: `${
                          JSON.parse(atob(localStorage.userToken.split(".")[1]))
                            .Email
                        }`,
                      },
                      {
                        headers: {
                          token: `${localStorage.userToken}`,
                        },
                      }
                    )
                    .then(async (res) => {
                      if (res.data == "Success") {
                        this.step = 3;
                        this.paymentStep = true;
                        this.done = true;
                      } else {
                        this.step = 3;
                        this.paymentStep = true;
                        this.done = false;
                      }
                      this.purchaseMessage = res.data;
                    });
                }
              }
            });
        } else {
          await axios
            .post(
              this.$apiUrl + "/registration/player/accept",
              {
                League_id: this.selectedRegistration.league_id,
                Season_id: this.selectedRegistration.season_id,
                Registration_id: this.selectedRegistration._id,
                Invitation_id: this.Invitation_id,
                Player_id: this.Client_id,
                payed_by: this.selectedRegistration.payed_by,
                Team_id: this.Team_id,
                Email: `${
                  JSON.parse(atob(localStorage.userToken.split(".")[1])).Email
                }`,
              },
              {
                headers: {
                  token: `${localStorage.userToken}`,
                },
              }
            )
            .then(async (res) => {
              if (res.data == "Success") {
                this.step = 3;
                this.paymentStep = true;
                this.done = true;
              } else {
                this.step = 3;
                this.paymentStep = true;
                this.done = false;
              }
              this.purchaseMessage = res.data;
              this.$emit("updateInvitations");
            });
        }
      }
    },
    async createAndMountFormElements() {
      this.$nextTick(() => {
        var elements = this.stripe.elements();

        this.cardNumberElement = elements.create("cardNumber");
        this.cardNumberElement.mount("#card-number-element");

        this.cardExpiryElement = elements.create("cardExpiry");
        this.cardExpiryElement.mount("#card-expiry-element");

        this.cardCvcElement = elements.create("cardCvc");
        this.cardCvcElement.mount("#card-cvc-element");
      });
    },
  },
  mounted() {
    this.stripe = window.Stripe(
      `pk_test_51IpzUoJo9H1nVra7gPlvde87eMaBmNsdi9h70Q86X2cAdAqrYVB5HVI5fyQIVr8jjFudKJscSdwBhaKTiUyElFrH00z0WpyUDd`
    );
  },
};
</script>

<style lang="scss" scoped>
.popup {
  background-color: #000000b6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .box {
    width: 40%;
    height: auto;
    z-index: 1010;
    box-shadow: #00000077 0 3px 7px;
    border: #555;
    background-color: white;
  }

  .background {
    background-color: #1d1d1d81;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1005;
  }
}

.steps {
  width: 100%;
  height: 30px;
  display: flex;
  margin-top: 1px;

  .step {
    box-shadow: 0px 1px 3px 0px rgb(0, 0, 0);
    flex: 1;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    font-size: 16px;
    font-family: "Times New Roman", serif;
  }
}

.inProcess {
  background-color: rgba(255, 166, 0, 0.747);
}

.done {
  background-color: rgba(0, 129, 0, 0.322);
}

.waiver {
  height: 600px;
  width: auto;
  border: 1px solid black;
  margin-top: 20px;
  overflow: scroll;
  overflow-x: hidden;
}

.terms {
  margin-left: 5%;
  font-weight: bold;
}

.paymentField {
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.281);
  margin: 5px;
  border-radius: 5px;
  width: 50%;
  margin-left: 25%;
}

.paymentLabel {
  padding: 3px;
  margin: 3px;
  width: 50%;
  margin-left: 25%;
}

.red {
  background-color: rgba(238, 0, 0, 0.829);
}

.card-row {
  text-align: center;
  margin: 20px 25px 10px;
}

.card-row span {
  width: 48px;
  height: 30px;
  margin-right: 3px;
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
}

.amex {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAeCAMAAABdem3zAAAAA3NCSVQICAjb4U/gAAACi1BMVEUAAAAAAAAAdKIAdqcGdqoDeqkDeKoDe6sFeqoFeqwCeqoGe6wGeasGeqwGe6wFeqwFeqwFeqsGe6oFeawEeqwEeqwFe6wHeaoFe6oFeasFe6wFeawHe6wIfKwJfKwKfa0Lfa0Mfq0Of64Pf64QgK8RgK8Sga8TgbAUgrAVgrAWg7EXg7EYhLEZhLIahbIbhbIdhrMfh7QgiLQhiLQjirUkirUli7YnjLYojLcqjbcsj7gtj7kukLkvkLkwkbkxkboykrozkro0k7s1k7s2lLs3lLw4lbw5lbw6lr07lr08l709l75Amb9Bmr9Dm8BFnMBHncFIncFJnsJKnsJLn8JMn8NNoMNOocRPocRQosRRosVUpMZVpMZWpcZXpcdYpsdZp8dap8dbqMheqclgqslhq8pjrMpkrMtnrsxpr8xqr81tsc5vss5wss9xs89ztNB0tdB1ttF6uNJ8udN9utN+utR/u9SAu9SBvNWCvNWDvdWEvdWGvtaHv9aIv9eKwNeMwdiPw9mQw9mRxNqTxdqUxtuVx9uWx9yXyNyYyNyZyd2ayd2byt2cyt6dy96fzN+gzN+hzd+izeCjzuCkzuCn0OGp0eKq0eKr0uOs0+Ot0+Ov1OSw1eSy1uWz1uW01+W32Oa62ui72+i82+i+3Om/3enC3urE3+vF4OvH4ezI4uzJ4u3K4+3L4+3N5O7O5e7P5e/R5u/S5/DT5/DV6PHW6fHX6fHY6vHa6/Lb7PPc7PPd7fPe7fTf7vTg7vTi7/Xj8PXk8fbm8vbn8vfo8/fp8/fq9Pjr9Pjs9fjt9fnu9vnv9vnw9/rx9/ry+Prz+Pv0+fv1+fv2+vz4+/z5+/37/P38/f7+/v7///+B6xdgAAAAHHRSTlMAARYaJ0FIT1pcYG6YmZyssrPDys3T2tvt9PX+1nJQbwAAAnFJREFUOMtjYOAWESMWiAqwMzBwyZAEOBn4SdMgzCBImgYJUjVI0UeDkoGBrq6BgZ6MhgECqAA56nJ6ICZIWN3AQAeuoevIrvOHDuy6ZLl1366ru3ft2nVl167dJ08cOXHo/P6Dl3Yd33Nm15mdJw+thGnQO2ei2nzDRaZp405Zmd2KxhYWW2TMTeUmJOWv0NOPKVJ1uNEi4329LByuoXKaabvZNZcQw8u5IUANrYuX7pA5eNSxJCk/OPfGBe2ZKotbnAw6kTSs8Axslpnh0mtRr74YqME7LGaHjI6G4uakfOfGG21q3c5hLf7TNDMQGhqUMjN9vFz6O2TCjgA11M+Zs13m4oXIvKT8bOs+i7DMNJks/xuhcggNKQ3b+vfGpS65kLTqVNyRpLi4uP1xl6d09jRPPF+blHC29WB+SsX5PXF1cA0lE/1lWiZOnFg2saZrIgxkgojiyr6JZTLxQFZ5ycSJpRTHdOAmMMiM2Agk103esGnTxiWzwELTVwOJyes29aFqiFtrCQR+x05FuVpaWqcfA3I8FlQDyandjpaWh5KtLI3RNCxTA8ZypHewb7vNrvWKk2QW7wiIzU3YteusXtXWrQvllm+diK5BRl6+4JyW2omJ2qkRiqtknN2VF+UCxWbmKCi5b3GU1fRE16B+4cK5RCe3pH6z6bP3nZOZsyYoMzftwsWrp4+skZt/4kA1mqfjVqgAgcORw/Z23kejg86r7JxXm1AIFOqzVdFLAEoahaNqiDgMBplZQGKNjC6QbD0MA3vmAomN5XTLcaQASQZe0jSIM3CQpoGPgZFHmgT1QkwMDAzMrOxEAjYWBgYAvI9h1MHdhQIAAAAASUVORK5CYII=")
    #fff;
}

.visa {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAeCAMAAABdem3zAAAAA3NCSVQICAjb4U/gAAABvFBMVEUAAADQ0NDa2tra2trZ2dnY2Nja2trt7e3t7e0mM3onNHspNXkqN30rN30sOH4tN3ovO4AwPIAyPoE1QYM3Q4Q4Q4U4RIU5RYY8R4g9SIhCTYtDToxGUY5HUo5JU49JVJBOWJJQW5RSXJVTXZZVX5dXYZhYYplaY5pfaJ1kbaBlbqFoaZFocaNpcqNqc6RtdqZvd6dzcpV0fKp2f6x5ga18g698hK99hK99hbB+hrCAh7GDi7OHjrWIj7aJkLeNk7mNlLqOlbqRl7yUmr6WnL6YnsCbocKepMSjqMekqceprsqrsMysscytss2uss2xts+xttC0uNG1udK1utK2utK3u9O6vdS7v9W8wNa9wda9wdfBxNnDx9rEx9vFyNzFydvHy93Kzd/Mz+DR0+LS1OPT1uTVnV/V1+XX2ebY2NjZuJbZ2+faoVza3Ojc3+rf4evf4ezi5O7j5e7n6fHp6/Lq6/Lr7PPsmC3snTfs7fPunjnu7/Tu7/Xw8fbx8vfy8/f09fj09fn19vn29/r3z5332LH39/r42LD42bL42bP5+fv76tX77dz7+/v7+/387dv9/f7+9ev//v3///9+dhG/AAAACXRSTlMAGxuq7e7u+vsOT6YMAAABbklEQVQ4y+WUV1cTYRQAlwSIsxoLltgLKgZ7AwV777FiL9gT1x4FGxpb0Gg0On/YBx83D+wz8z7nu+fe800QpNKtpTHSmk4FQUt7pu4YqWfaW4L0BBOQSQdt9SRCvS0omYjSOBZ+fB0d/f5T/VQoDHi6cF4b1/Zt6d9fUZ+cLFyMvfDny6vhN3/1EOwegW4/LAHgpb6bBpNr8ZE2PBz+rQvIvrgJ2+2DdaeOba7pXoBbceHAxHvfHIRNHoHjLobLqlZnkIeDcaHIlAeuh6Jb4bb9EG58rh6G4nTWNNnSHFYNZcnrcsKK1d4Qpl63MY9lrmRmE6GHcCdc0Q7mqt5ZAfM9C7uKvfA0LlyASSzUt7Daz+pIyGw7+c+JuPAxCxzV+7DHrq5tOzqhbxA6crlcSE+TS+dhVk0vwRk7AFhb64a76lIWva7EhKEoKqvvo6jqs6sD526UNYoeq5ajR78a4/k/JM5M4pAlTGUqSBrjf5znrWNE0ZcCAAAAAElFTkSuQmCC")
    #fff;
}

.mastercard {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAeCAMAAABdem3zAAAAA3NCSVQICAjb4U/gAAACc1BMVEUAAADQ0NDa2tra2trZ2dnY2Nja2trt7e3t7e3MAADMAQHMBATNCQnOCwvODAzODg7PDwnPERHRGxvSFgfSHh7SHx/SIB7THhDTJCTTJibTJyfUKSnVIAXVLS3VMDDWMjLWNTXWNjbXIQDXLyLXNS7XNzfXOzvYLxbYPT3YPj7Y2NjZOy/ZRUXaSEjaSUnbLQDbLgDbS0vbTU3cSj/cU1PdNADdSjTdVVXdVlbeNwDeW1vfYmLgUDPgZWXgZmbgaGjhXkvhamrhbW3ia2Lib2/jXDfja1njdXXkeHjkeXnke3vlgIDlgYHmg4PmhITmhobnh4fniIjni4voVgDojIzokJDqXADqaiTqlpbqmJjqmZnqmprrnJzrn5/tpqbuqqrura3urq7vsbHvsrLvs7PwbADwbQDwtLTwtbXwt7fxvLzycgDyjULyvr7yv7/zdQDzmVvzn2fzxMTzxcXzx8f2fwD21tb3gQD3x6/3ybL32Nj4hAD43t7439/44OD5iQD54eH54uL65ub65+f76+v7+/v88vL89PT99/f9+Pj9+fn+lwD+/f3/mQD/mgT/nQv/nw//oRT/oRX/oRb/ohj/qCf/qSn/qSr/qy3/rDH/rjX/rjb/sT7/sj//s0L/tEX/tUf/tUj/tkn/t0v/uVD/uVH/u1X/vFj/vVr/vl7/v2H/w2n/xGz/x3P/yHb/yXr/zob/z4j/0Iv/1Zj/1pr/153/2J7/26X/3q7/4LL/4LP/4bX/4bb/5cD/5sL/58P/58T/58X/6sz/7NH/7dL/8d7/8t//9OX/9eb/9ef/9ur/9+v/+vT/+/X//Pj//fz///90HdR0AAAACXRSTlMAGxuq7e7u+vsOT6YMAAABmElEQVQ4y2NgYGJm0SISsDAzMTAwsrG3XiAStLKzMTIwc1wgAbAzM7C2kqKhlZVB6wJJQItSDS3R5orSmo7pPUD2+d2r506bvWzLKdwaOr14OSFAMuXCzqm9ENC//hwODc2KnHDAVdCLALNOYNXQLo9QzylgGoykY+YZbBqckNSrpKamdSPpWINFQw03kgZhJSUlSyQNfUcxNfjzyfFISUDUi5WCQO+EOZOgGmZswNSgY3VBpyPOxJZf1d4uWdxZW9k45+SBtStWTVowffH8o/MxNUgHNsY0entmxrW5R6VnhNb6NlVu6p247uCOs3sOH941DYuG9MTa3JCiGp+S+CzdrrziBOuK5b1L9x8/tG3vko0bsWjQafCKaIhNqon0qyvzqApyKZMtPLZl8/bTR1Zv3Xd6JRYnBeiJChkJGqppWIgoKKi7mTnIVC9YPHnhnHlTJiyaM3EDgWANz87OLicQrCgRx6VvYJBPIOJQk4ZNWD3BpIGS+DhdCSc+0pM3JAOpkpCBaJOnSS5mSC7ISCwqgYUriYUxAINRRW57ksG5AAAAAElFTkSuQmCC")
    #fff;
}

.Button {
  width: 250px;
  height: 40px;
  margin-top: 30px;
  padding: 5px 50px 5px 50px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-size: 15px;
  letter-spacing: 1px;
  border: 0px solid;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 20px;
  cursor: pointer;
}

.Button:disabled {
  background-color: rgb(131, 131, 131);
}
</style>
